import { CamelCaseKeys } from "@regal-voice/shared-types";
import { camelCase } from "lodash";

/****************************************************************************
 * PLEASE KEEP FILE ALPHABETIZED                                            *
 * PLEASE MATCH THE FLAG KEY EXACTLY FROM LAUNCH DARKLY (INCLUDING CASING)  *
 ****************************************************************************/
export const FLAG_DEFAULTS = {
    "admin-contact-attributes": true,
    "admin-settings": false,
    "agent-task-data": true,
    "agents-page": false,
    "allow-incoming-call-while-on-another-call": false,
    "allow-static-segments": true,
    "allow-transactional-campaigns-bypass": true,
    "allow-update-contact-node-and-scheduled-journeys": false,
    "allowed-voicemail-duration": 20,
    "audible-notifications-polling-frequency": 5,
    "audience-page": true,
    "audience-search-filter": true,
    "auto-accept-inbound-calls-in-conversations": false,
    "brand-numbers": false,
    "calendly-integration": true,
    "campaign-manual-type": false,
    "campaign-specific-voicemail": false,
    campaigns: false,
    "can-share-contact-lists": false,
    "ck-dialpad": true,
    "ck-external-transfers": true,
    "ck-internal-transfers": true,
    "ck-queue-internal-transfers": false,
    "ck-transfers": true,
    "collapsed-call-controls": false,
    "communication-templates-use-preview": false,
    "communication-templates": 0,
    "completed-tasks-count": true,
    "conditional-node-is-one-of": true,
    "conditional-node-relative-date-comparison": true,
    "conditional-node-values-autocomplete": true,
    "consolidate-task-summary": false,
    "contact-list-create-saved-views": false,
    "contact-list-saved-views": false,
    "contact-list-sorting": false,
    "contact-list": false,
    "conversation-intelligence-ai-summary": false,
    "conversation-intelligence-score-cards": false,
    "conversation-intelligence-scorecard-metrics": false,
    "conversations-intelligence-insights": false,
    "conversations-service-accept-task": false,
    "conversations-service-complete-task": true,
    "create-user-updatable-roles": false,
    "custom-cancel-task-enabled": true,
    "custom-objects-v1": false,
    "custom-voicemail-recordings": true,
    dashboards: false,
    "data-dog-browser-logs-level": "debug",
    "data-dog-browser-logs-sample-rate": 100,
    "data-dog-browser-logs": true,
    "data-dog-rum-sample-rate": 100,
    "data-dog-rum": true,
    "default-contact-active-tab": "contactProfileTab",
    "disable-edit-multiple-phones-and-emails": false,
    "display-dynamic-segments": false,
    "dynamic-segments-conditions": false,
    email: 0,
    "enable-call-recording-pause": true,
    "enable-campaign-priority-for-all-dialer": false,
    "enable-manager-barge": false,
    "enable-manager-listen": false,
    "enable-outbound-MMS": false,
    "enable-parallel-dialing-mode": false,
    "enable-progressive-dialer": false,
    "enable-task-reassignment": false,
    "enable-team-specific-task-outcomes": false,
    "enable-tracker-ai-assist": false,
    "enable-transfer-to-busy-agents": false,
    "enables-the-ai-scorecards-edit": false,
    "enforce-mic-permissions": "hide",
    "fetch-contact-through-regal-api": false,
    "get-pending-reservations-from-RDS": false,
    "green-input-checkmark": true,
    "has-external-transfer-phonebook": false,
    "has-ivr-demo-nodes": false,
    "has-ivr-send-to-agent-v2-keypress-exit": false,
    "has-ivr-send-to-agents-v1-node": false,
    "has-ivr-send-to-agents-v2-node": false,
    "has-ivr-vm-and-asap": false,
    "has-ivr-webhook-node": false,
    "has-journey-update-contact-node": false,
    "has-journey-update-contact-trigger-events": false,
    "has-lexical-campaign-editor": false,
    "has-pre-recorded-vm-drops": false,
    "has-pre-recorded-vm-drops-agent-view": false,
    "has-self-serve-skills-delete": false,
    "has-self-serve-skills": false,
    "has-self-serve-task-attributes-edit": false,
    "has-vm-drop-sub-brand-selection": false,
    "has-self-serve-task-attributes": false,
    "hubspot-integration": true,
    "inbound-mms": true,
    "insights-use-cte": false,
    "is-demoing-agentless-call": false,
    "is-demoing-custom-ai-task": false,
    "is-demoing-eight-by-eight": false,
    "is-demoing-email-task": false,
    "is-demoing-five-9": false,
    "is-demoing-nice": false,
    "is-demoing-genesys": false,
    "is-demoing-ringless-vm": false,
    "is-demoing-send-email": false,
    "is-demoing-sms-ai": false,
    "is-demoing-talkdesk": false,
    "is-demoing-voice-ai": false,
    "ivr-page": false,
    "ivr-use-lexical-editor": false,
    "journey-builder-add-contact-to-segment-trigger": true,
    "journey-builder-allow-pageview-trigger": false,
    "journey-builder-allow-schedule-trigger": false,
    "journey-builder-assign-target-agent": false,
    "journey-builder-check-cycle": true,
    "journey-builder-custom-sms-task-backend-completed": false,
    "journey-builder-custom-sms-task": false,
    "journey-builder-custom-task": false,
    "journey-builder-reminder-node": true,
    "journey-builder-scheduled-callback-node": true,
    "journey-builder-update-task": false,
    "journey-builder-user-count-cutoff-date": "2022-07-19",
    "journey-builder": false,
    "journey-hide-unflatten-opt-in": false,
    "journey-sticky-notes": true,
    "journeys-dynamic-failed-counters": true,
    journeys: true,
    "known-worker-routing-setting": false,
    "max-number-business-profiles": 1,
    "multi-tab-detection": true,
    "multiple-calls": false,
    "multiple-contact-owners": false,
    "network-quality-monitor": false,
    "new-product-alert-url": "",
    "no-autocomplete-dialpad": false,
    "open-task-url-when-accepted": "",
    "promote-events-activity-feed": false,
    "proxy-feed-events": false,
    "queues-page": false,
    "queues-task-order": false,
    "react-flow-11": false,
    "recent-activity-lookback-window": 2,
    recentActivity: false,
    "regal-live-dummy-data": false,
    "regal-live-page": false,
    "reject-task-enabled": false,
    "relative-date-format-url": "https://regalvoice.slab.com/posts/relative-dates-udcoeepu",
    "rollout-ui-unauthorized-interception": false,
    "salesforce-integration": false,
    "scheduled-callback-request-activity-feed": true,
    ScheduleSMSTask: false,
    "segment-conditions-allow-custom-event": false,
    "segment-conditions-allow-regal-event": false,
    "segment-csv-data-types-row": false,
    segments: false,
    "send-agent-desktop-attributes-as-datetime": false,
    "session-replay-recording": true,
    "session-replay-sample-rate": 100,
    "show-agent-desktop-stats-advanced": false,
    "show-agent-desktop-stats": false,
    "show-bcid-actions-column": false,
    "show-branded-numbers": false,
    "show-business-profiles": false,
    "show-call-transcript-available-event-for-journeys": false,
    "show-campaign-name-on-task-card": false,
    "show-cleanup-orphaned-twilio-chats": false,
    "show-disposition-on-tasks-page": true,
    "show-event-management": false,
    "show-new-product-alert": false,
    "show-node-user-count": false,
    "show-nor-operator": false,
    "show-queue-column-past-tasks": false,
    "show-queues-settings-page": false,
    "show-reserved-agents": false,
    "show-routing-rules": false,
    "show-saved-views": false,
    "show-sentiment-in-activity-feed": false,
    "show-sentiment": false,
    "show-task-reassignment-panel": false,
    "show-time-since-last-accepted-task": false,
    "show-transcript-summary-in-activity-feed": false,
    "show-unreserved-target-agent-tasks": false,
    "show-user-management": false,
    "show-wrap-up-banner": true,
    "show-zendesk-widget": false,
    showTargetAgentColumn: true,
    "snooze-task": true,
    "sorted-task-list": false,
    "support-for-opportunities": false,
    "target-agent-task-filter-cache-time": 300000,
    "task-creation-stabilization": false,
    "task-preferences-auto-accept": false,
    "task-preferences-auto-complete": false,
    "task-preferences-auto-open-url": false,
    "task-preferences-by-team": false,
    "task-preferences-cancelation": true, // this is the only flag used to control visibility of tasks preferences settings page
    tasks: true,
    "team-management-visible": false,
    "team-specific-communication-templates": false,
    "team-specific-contact-attributes": false,
    "transcript-scorecard-cta": false,
    "transcriptions-filters": { talk_time: false, trackers: false },
    "transcriptions-page": false,
    "transcripts-list-saved-views": false,
    "transcripts-page-sidebar": false,
    "twilio-device-audio-constraints": false,
    "twilio-workflow-json-read-only": true,
    "type-task-filter-cache-time": 300000,
    "use-open-search-for-my-contacts-list": false,
    "use-sub-brand-for-lookup": false,
    "user-activity-polling-interval": 10000,
    "user-notifications-polling": true,
    "user-notifier-monitoring": true,
    "voice-sdk-close-protection": false,
    "voice-sdk-dd-logger": false,
    "voice-sdk-log-level": "SILENT",
};
/****************************************************************************
 * PLEASE KEEP FILE ALPHABETIZED                                            *
 * PLEASE MATCH THE FLAG KEY EXACTLY FROM LAUNCH DARKLY (INCLUDING CASING)  *
 ****************************************************************************/

// we have to hardcode some flags that are created with uppercase acronyms in LD but are not handled properly by our CamelCaseKeys utility
export type FlagTypes = CamelCaseKeys<typeof FLAG_DEFAULTS> & {
    scheduleSmsTask: boolean;
    enableOutboundMms: boolean;
};

export type Flag = keyof FlagTypes;

export const FLAGS_DEFAULTS_FORMATTED = Object.entries(FLAG_DEFAULTS).reduce<Record<string, unknown>>(
    (acc, [key, value]) => {
        acc[camelCase(key)] = value;
        return acc;
    },
    {}
) as FlagTypes;
