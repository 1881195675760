import { useLocation } from "react-router";

import AgentDesktopSideNav from "Pages/agentDesktop/agentInterface/AgentDesktopSideNav/AgentDesktopSideNav";
import { getEmbedUiConfigByKey } from "Services/embed/CrossDomainMessenger";
import { useFlags } from "Services/FeatureFlagService";

import { PhoneNumbersSideNavBar } from "../PhoneNumbersSideNavBar/PhoneNumbersSideNavBar";
import SettingsSideNavBar from "../SettingsSideNavBar/SettingsSideNavBar";
import TranscriptsSideNavBar from "../TranscriptsSideNavBar/TranscriptsSideNavBar";

export function Subnav(): JSX.Element {
    const { contactList, transcriptsPageSidebar } = useFlags();
    const { pathname } = useLocation();

    const currentMainRoute = pathname.split("/")[1];
    const sub = pathname.split("/")[2];
    const hideSubnavWhenEmbedded = getEmbedUiConfigByKey("hideNavigation");

    const showADSideNav = contactList && sub != "settings" && !hideSubnavWhenEmbedded;

    switch (currentMainRoute) {
        case "transcripts":
            return transcriptsPageSidebar ? <TranscriptsSideNavBar /> : <></>;
        case "settings":
            return <SettingsSideNavBar />;
        case "phone-numbers":
            return <PhoneNumbersSideNavBar />;
        case "agent":
            return showADSideNav ? <AgentDesktopSideNav /> : <></>;
        default:
            return <></>;
    }
}
